import { OIDS_DICTIONARY } from '../constants';
import { TagsTranslations } from '../constants/tags-translations';

export interface TagTranslation {
  description: string;
  title: string;
  isTranslated: boolean;
}

/**
 * ������ ���������� �� ������ � ����������� � �����������
 *
 * @param tagsTranslations - ������� � ������������� �����
 * @param rawInfo - ������ ��� ��������
 * @returns �������������� ���������� �� ��������� �����
 */
export const _parseCertInfo = (tagsTranslations: TagsTranslations[], rawInfo: string): TagTranslation[] => {
  const extractedEntities: string[] = rawInfo.match(/([�-��-�a-zA-Z0-9\s.]+)=(?:("[^"]+?")|(.+?))(?:,|$)/g);

  if (extractedEntities) {
    return extractedEntities.map((group) => {
      const segmentsMatch = group.trim().match(/^([�-��-�a-zA-Z0-9\s.]+)=(.+?),?$/);
      let title = segmentsMatch?.[1];
      // �������� ������ �������
      const description = segmentsMatch?.[2]?.replace(/^"(.*)"/, '$1')?.replace(/"{2}/g, '"');
      const oidIdentifierMatch = title?.match(/^OID\.(.*)/);
      const oidIdentifier = oidIdentifierMatch?.[1];
      let isTranslated = false;

      // ���� ����� � ������ ���, �������� ��� ������������
      if (oidIdentifier) {
        const oidTranslation = OIDS_DICTIONARY[oidIdentifier];

        if (oidTranslation) {
          title = oidTranslation;
          isTranslated = true;
        }
      }

      const tagTranslation = tagsTranslations.find((tag) => tag.possibleNames.find((name) => name === title))
        ?.translation;

      if (tagTranslation) {
        title = tagTranslation;
        isTranslated = true;
      }

      return { description, title, isTranslated };
    });
  }
};
