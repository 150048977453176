import { _afterPluginsLoaded } from '../helpers/_afterPluginsLoaded';
import { _extractMeaningfulErrorMessage } from '../helpers/_extractMeaningfulErrorMessage';
import { __cadesAsyncToken__, __createCadesPluginObject__, _generateCadesFn } from '../helpers/_generateCadesFn';

/**
 * ������� ��������� ���������� � �������� � Cades ������� ��������
 *
 * @callback executeCallback
 * @param exposedAPI - API ��������� ��� ��������� ����������
 * @param exposedAPI.cadesplugin - Cades ������, ��������������� ���������
 * @param exposedAPI._generateCadesFn - �������, ������������� ���� ������������ javascript ������� ��� ������� �����
 * @param exposedAPI.__cadesAsyncToken__ - ������������� ������, ����������� � ��������
 * @param exposedAPI.__createCadesPluginObject__ - ������� ��� �������� ����������/����������� Cades ��������
 * @param exposedAPI._extractMeaningfulErrorMessage - ������ ��� ���������� ������ ������
 *
 * @returns ��������� ���������� ��������� ����������
 */

/**
 * ����������� � ��������� ���������� ������� ��� ��������� ���������� ����� (����������/�����������)
 *
 * @param {executeCallback} callback - �������, ������������ �������������� ������ � Cades �������
 *
 * @returns ����������� ��������� ���������� ������������ �������
 */
export const execute = _afterPluginsLoaded(
  async (
    callback: (exposedAPI: {
      cadesplugin: any;
      _generateCadesFn: (callback: Function) => string;
      __cadesAsyncToken__: object;
      __createCadesPluginObject__: (...args) => any;
      _extractMeaningfulErrorMessage: (error: Error) => string | null;
    }) => any,
  ): Promise<any> =>
    await callback({
      cadesplugin: window.cadesplugin,
      _generateCadesFn,
      __cadesAsyncToken__,
      __createCadesPluginObject__,
      _extractMeaningfulErrorMessage,
    }),
);
