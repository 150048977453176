import { Certificate } from './certificate';
import { _afterPluginsLoaded } from '../helpers/_afterPluginsLoaded';
import { getUserCertificates } from './getUserCertificates';

/**
 * ���������� ���������� �� ���������
 *
 * @param thumbprint - ��������� �����������
 * @returns ����������
 */
export const getCertificate = _afterPluginsLoaded(
  async (thumbprint: string): Promise<Certificate> => {
    if (!thumbprint) {
      throw new Error('��������� �� ������');
    }

    const availableCertificates: Certificate[] = await getUserCertificates();
    const foundCertificate: Certificate = availableCertificates.find((cert) => cert.thumbprint === thumbprint);

    if (!foundCertificate) {
      throw new Error(`���������� � ����������: "${thumbprint}" �� ������`);
    }

    return foundCertificate;
  },
);
