export const OIDS_DICTIONARY = {
  '1.2.643.2.2.34.6': '������������ ������ �����������',
  '1.2.643.2.39.1.1': '������������� � ����������� ��������� ������� "1�:����������� 8"',
  '1.2.643.3.131.1.1': '���',
  '1.2.643.3.141.1.1': '��� ���',
  '1.2.643.3.141.1.2': '�� ���',
  '1.2.643.3.2.100.65.13.11': '������������� � ������� ��� "����������" ����������� �������.',
  '1.2.643.3.8.100.1': '���������� ���� "ekey-����"',
  '1.2.643.3.8.100.1.1': '����� ������������� � �������� ��� ��� ����� ��������� ���������� ����������',
  '1.2.643.3.8.100.1.10':
    '��� ������� � ����������� ������ � ���������� ���������������� ��������� �  �������������� �������� ���������� �������� ������ ������ ���������������  ������ ���������� ���������� ���������������� ������ ������ ������',
  '1.2.643.3.8.100.1.11':
    '���������� ����������� ���������� � ������������������ ��������������  ������� ���������� ���������������� � �������������� ������ ����������� �������',
  '1.2.643.3.8.100.1.12': '������������� � ������� ���������������� ������ ��������� �������',
  '1.2.643.3.8.100.1.13':
    '������������� � ����������� �������� �������� ��������� ����������������  ������ ������������� ����',
  '1.2.643.3.8.100.1.14': '������������� � ����������� �������� �������� "������"',
  '1.2.643.3.8.100.1.2': '�������� ���������� �� ���',
  '1.2.643.3.8.100.1.3': '���������� �������� ������������, ����������, ���������, ����� � �.�.',
  '1.2.643.3.8.100.1.4': '������������������� ���������������',
  '1.2.643.3.8.100.1.5': '������������� � �������� ����������� ��������',
  '1.2.643.3.8.100.1.6': '������������� � �������-���������� ������� "�������"',
  '1.2.643.3.8.100.1.7': '������������� � ������� ������ ��������������� ������� ��������������� ����.',
  '1.2.643.3.8.100.1.8': '������������� � ������ ������� ����������� �������� B2B-Center � B2G.',
  '1.2.643.3.8.100.1.9':
    '��� ������� � ����������� ������ � ���������� ���������������� ��������� �  ����������� �������� ��� ����ϻ ��������������� ������ ���������� ����������  ���������������� ��� �������������� ������',
  '1.2.643.5.1.24.2.1.3':
    '������������ ���������� ��� ��������� ���������������  ����� � ����� ������� ���������������� �������� ������������ �� ������� ���������',
  '1.2.643.5.1.24.2.1.3.1':
    '������������ ����������� ��������� ����������  ��� ��������� ��������������� ����� � ����� ������� ���������������� �������� ������������ �� �������  ���������',
  '1.2.643.5.1.24.2.2.2': '������������ ���������� ��� ���������� ��������  ������ �� ������� ������� ����������� ����',
  '1.2.643.5.1.24.2.2.3':
    '������������ ���������� ��� ��������� ���������������  ����� � ����� ��������������� ����������� ���� �� ���������� ��������� � ������ � ��� �� ������� ���������',
  '1.2.643.6.2.1.7.1':
    '������������� ����������� �������������� ������� ������������ ���� ��� ��������������� ��������������� ������������ ���� � ����������, ��������� � ��������������, ����������� (��������������) � ������������ ����������� � ���� ���� � ������������ � ����� ������������������ ����������� �����������, ������������������ ����������� �����������, � ����� ������������ ������ �������������� ������, ����������� �������������� ������, ���������������� ���������� ����� ������ �����, � ����� ��������� � ������������� ��������� ������������ ����� ��������� � ���� �����������',
  '1.2.643.6.2.1.7.2':
    '������������� ���������� ����� � ����������, ��������� � ��������������, ����������� (��������������) � ������������ ����������� ���� � ������������ � ��������� �������������� ���� ������ �������������� ������, � ��� ����� ���������, ��������� � ������ �/��� ��������� ���� �� �������������� ��� ������ �������������� ������',
  '1.2.643.6.3':
    '������������� � ����������� �������� �������� � � ����������� �����������, ��������� � ������� ����������� ���������',
  '1.2.643.6.3.1.1': '������������� �� ����������� �������� ���������� ��� ���������� ��������� � ����������� �����',
  '1.2.643.6.3.1.2.1': '��� ��������� - ����������� ����',
  '1.2.643.6.3.1.2.2': '��� ��������� - ���������� ����',
  '1.2.643.6.3.1.2.3': '��� ��������� - �������������� ���������������',
  '1.2.643.6.3.1.3.1': '�������� ���������� ������',
  '1.2.643.6.3.1.4.1': '������������� �����������',
  '1.2.643.6.3.1.4.2': '�������������� ����������',
  '1.2.643.6.3.1.4.3': '���������� � ������ ������� ���������',
  '1.2.840.113549.1.9.2': '������������������� ���',
  '1.3.6.1.4.1.24138.1.1.8.1': '����������� ����������� ���������� � ������� "����������� �������� ��������"',
  '1.3.6.1.4.1.29919.21': '������������� � ������� ������ ��������������� �������  ���������� ������� "������".',
  '1.3.6.1.5.5.7.3.2': '�������� ����������� �������',
  '1.3.6.1.5.5.7.3.4': '���������� ����������� �����',
  '1.3.643.3.8.100.15': '������������� � ��� "uTender"',
};
