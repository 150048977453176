import { TagsTranslations } from './tags-translations';

export const ISSUER_TAGS_TRANSLATIONS: TagsTranslations[] = [
  { possibleNames: ['UnstructuredName'], translation: '������������������� ���' },
  { possibleNames: ['CN'], translation: '�������������� �����' },
  { possibleNames: ['C'], translation: '������' },
  { possibleNames: ['S'], translation: '������' },
  { possibleNames: ['STREET'], translation: '�����' },
  { possibleNames: ['O'], translation: '��������' },
  { possibleNames: ['OU'], translation: '���' },
  { possibleNames: ['T'], translation: '���������' },
  { possibleNames: ['����', 'OGRN'], translation: '����' },
  { possibleNames: ['������', 'OGRNIP'], translation: '������' },
  { possibleNames: ['�����', 'SNILS'], translation: '�����' },
  { possibleNames: ['���', 'INN', '��� �����������'], translation: '���' },
  { possibleNames: ['E'], translation: 'Email' },
  { possibleNames: ['L'], translation: '�����' },
];
